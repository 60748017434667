import Engine from 'publicodes'
import rules from 'modele-social'

export default function evaluateSalary(brut, parts, benefits) {
    const engine = new Engine(rules)

    let net
    if (!benefits) {
        let netImposableFromBrut = engine
            .setSituation({
                "salarié . contrat": "'CDI'",
                "salarié . contrat . salaire brut": brut,
                "dirigeant": "non"
            })
            .evaluate("salarié . rémunération . net . à payer avant impôt")

        let netImposableReduced = netImposableFromBrut.nodeValue / parts
        let netPaidFromNetImposableReduced = engine
            .setSituation({
                "salarié . contrat": "'CDI'",
                "salarié . rémunération . net . à payer avant impôt": netImposableReduced,
                "dirigeant": "non"
            })
            .evaluate("salarié . rémunération . net . payé après impôt")

        net = netImposableFromBrut.nodeValue - (netImposableReduced - netPaidFromNetImposableReduced.nodeValue) * parts
    }
    else {
        let netImposableFromBrut = engine
            .setSituation({
                "salarié . contrat": "'CDI'",
                "salarié . contrat . salaire brut": brut,
                "dirigeant": "non"
            })
            .evaluate("salarié . rémunération . net . à payer avant impôt")

        let netImposableReduced = netImposableFromBrut.nodeValue * 0.7 / parts
        let netPaidFromNetImposableReduced = engine
            .setSituation({
                "salarié . contrat": "'CDI'",
                "salarié . rémunération . net . à payer avant impôt": netImposableReduced,
                "dirigeant": "non"
            })
            .evaluate("salarié . rémunération . net . payé après impôt")

        net = netImposableFromBrut.nodeValue - (netImposableReduced - netPaidFromNetImposableReduced.nodeValue) * parts
    }

    return net
}
