import React, { useEffect, useCallback, useState, useRef } from 'react'
import _debounce from 'lodash/debounce';
import './Careers.css'

import { PercentBadgeIcon, CurrencyEuroIcon, CheckBadgeIcon, CircleStackIcon, BanknotesIcon, FaceSmileIcon, LanguageIcon, ShieldCheckIcon, DocumentMagnifyingGlassIcon } from '@heroicons/react/20/solid'
import evaluateSalary from '../../helpers/evaluateSalary'

// Import components
import ContactForm from '../../components/ContactForm';
import JobsHero from '../../components/JobsHero';
import RevenueTile from '../../components/RevenueTile';
import RoleTile from '../../components/RoleTile';
import CareerRequirements from '../../components/CareerRequirements';


function Jobs(props) {
    const { isEnglish } = props
    const mobileRef = useRef(null)

    const [hasScrolled, setHasScrolled] = useState(false)
    const [toggleRoleDetails, setToggleRoleDetails] = useState(null)
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(35000);
    const [share, setShare] = useState(30)
    const [minShare, setMinShare] = useState(25)
    const [maxShare, setMaxShare] = useState(35)
    const [parts, setParts] = useState(1)
    const [married, setMarried] = useState(false)
    const [kids, setKids] = useState(0)
    const [gross, setGross] = useState(null)
    const [net, setNet] = useState(null)
    const [buttonClicked, setButtonClicked] = useState('dentist')
    const [netReduced, setNetReduced] = useState(null)

    const debounceEvaluateSalary = (brut, parts) => {
        let resultNet = evaluateSalary(brut, parts, false)
        setNet(Math.round(resultNet))

        let resultNetReduced = evaluateSalary(brut, parts, true)
        setNetReduced(Math.round(resultNetReduced))
        setLoading(false)
    }

    function handleScroller() {
        if (window.innerWidth < 500 && !hasScrolled) {
            mobileRef?.current?.scrollIntoView({
                behavior: 'smooth'
            })
            setHasScrolled(true)
        }
    }

    const debounceFn = useCallback(_debounce(debounceEvaluateSalary, 500), [])

    function anchorScroll(anchor) {
        document.querySelector(anchor).scrollIntoView({
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        if (!localStorage.getItem('careers')) {
            //Effects
            const bounceInUp = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('bounceInUp');
                    }
                });
            });
            bounceInUp.observe(document.querySelector('.jobHero'));
            bounceInUp.observe(document.querySelector('.jobHeroPattern'));
            localStorage.setItem('careers', true)
        }
    }, [])

    useEffect(() => {
        setGross(value * share / 100)
        debounceFn(value * share / 100, parts)
    }, [value, share, parts, debounceFn])

    useEffect(() => {
        let mesParts = 1
        if (married) {
            mesParts += 1
        }
        if (kids > 0) {
            if (kids <= 2) {
                mesParts += kids * 0.5
            }
            else {
                mesParts += kids - 1
            }
        }
        setParts(mesParts)
    }, [married, kids, debounceFn])

    return (
        <div className='overflow-x-hidden'>
            <div className='pt-32 sm:mt-20 sm:pt-24'>
                <JobsHero
                    isEnglish={isEnglish}
                    benefits={[
                        isEnglish ? 'Competitive salaries' : 'Salaires compétitifs',
                        isEnglish ? 'Flexible work hours' : 'Horaires de travail flexibles',
                        isEnglish ? '5 weeks of paid vacation' : '5 semaines de congés payés',
                        isEnglish ? 'Support with relocation' : "Aide à l'expatriation",
                        isEnglish ? 'Benefits for you and your family' : "Avantages pour vous et votre famille",
                        isEnglish ? 'A great work environment' : 'Environnement de travail agréable',
                    ]}
                    anchorScroll={anchorScroll}
                />
            </div>

            {/* Our job opportunities */}
            <div className='px-6 sm:px-32 mt-24 sm:mt-32'>
                <div className="relative bg-white py-8 sm:py-12 px-4 sm:px-8 shadow-xl  rounded-xl text-center" id="careers">
                    <div className='heroPatternStars absolute bottom-0 left-0 -translate-x-24 translate-y-8 sm:-translate-x-12 sm:translate-y-12 -z-10' />
                    {window.innerWidth < 500 && <div className='heroPatternStarsH absolute top-0 right-0 translate-x-32 -translate-y-8 sm:translate-x-12 sm:-translate-y-12 -z-10' />}
                    <h3 className="text-md sm:text-lg font-semibold leading-7 text-indigo-600">{isEnglish ? "CAREERS IN FRANCE" : "CARRIERES EN FRANCE"}</h3>
                    <h2 className="mt-0 sm:mt-2 text-2xl sm:text-3xl font-bold tracking-tight text-gray-900">
                        {isEnglish ? "WE ARE HIRING" : 'ON RECRUTE'}
                    </h2>
                    <div className='mt-0 sm:mt-12'>
                        <RoleTile
                            index={'dentist'}
                            isEnglish={isEnglish}
                            setCurrentScreen={props.setCurrentScreen}
                            anchorScroll={anchorScroll}
                            toggleIndex={1}
                            toggleRoleDetails={toggleRoleDetails}
                            setToggleRoleDetails={setToggleRoleDetails}
                            title={isEnglish ? "Dentist" : 'Dentiste'}
                            schedule={isEnglish ? "Full Time" : 'Temps Plein'}
                            location="Chateauroux"
                            salaryRange={isEnglish ? "6,000 to 15,000EUR" : "6,000 à 15,000EUR"}
                            timeline={isEnglish ? "Summer 2025" : 'Eté 2025'}
                            origin='careers'
                            facts={[
                                { name: isEnglish ? 'Revenue Share' : 'Part des Revenus', value: '30+%' },
                                { name: isEnglish ? 'Minimum Guaranteed Salary' : 'Salaire Minimum Garanti', value: '3,000 EUR' },
                                { name: isEnglish ? 'Work Schedule' : 'Temps de Travail', value: isEnglish ? '35 hours / week' : '35 heures / semaine' },
                                { name: isEnglish ? 'Paid Holidays' : 'Congés Payés', value: isEnglish ? '5 weeks / year' : '5 semaines / an' },
                                { name: isEnglish ? 'Free Housing' : 'Logement offert', value: isEnglish ? 'Up to 6 months' : "Jusqu'à 6 mois" },
                                { name: isEnglish ? 'Dedicated Assistant per Doctor' : 'Assistante dédiée par Médecin', value: '1' },
                                { name: isEnglish ? 'Admin Work' : 'Administratif', value: '0' },
                                { name: isEnglish ? 'Contract clauses' : 'Clauses dans le contrat', value: '0' },
                            ]}
                        />
                        <RoleTile
                            index={'GP'}
                            isEnglish={isEnglish}
                            setCurrentScreen={props.setCurrentScreen}
                            anchorScroll={anchorScroll}
                            toggleIndex={2}
                            toggleRoleDetails={toggleRoleDetails}
                            setToggleRoleDetails={setToggleRoleDetails}
                            title={isEnglish ? "General Practitioner" : 'Médecin Généraliste'}
                            schedule={isEnglish ? "Full Time" : 'Temps Plein'}
                            location="Chateauroux"
                            salaryRange={isEnglish ? "6,900 to 10,000EUR" : "6,900 à 10,000EUR"}
                            timeline={isEnglish ? "Hiring" : 'Immédiatement'}
                            origin='careers'
                            facts={[
                                { name: isEnglish ? 'Revenue Share' : 'Part des Revenus', value: '50%' },
                                { name: isEnglish ? 'Minimum Guaranteed Salary' : 'Salaire Minimum Garanti', value: '6,900 EUR' },
                                { name: isEnglish ? 'Work Schedule' : 'Temps de Travail', value: isEnglish ? '35 hours / week' : '35 heures / semaine' },
                                { name: isEnglish ? 'Paid Holidays' : 'Congés Payés', value: isEnglish ? '5 weeks / year' : '5 semaines / an' },
                                { name: isEnglish ? 'Free Housing' : 'Logement offert', value: isEnglish ? 'Up to 6 months' : "Jusqu'à 6 mois" },
                                { name: isEnglish ? 'Dedicated Assistant per Doctor' : 'Assistante dédiée par Médecin', value: '1' },
                                { name: isEnglish ? 'Admin Work' : 'Administratif', value: '0' },
                                { name: isEnglish ? 'Contract clauses' : 'Clauses dans le contrat', value: '0' },
                            ]}
                        />
                        <RoleTile
                            index={'specialist'}
                            isEnglish={isEnglish}
                            setCurrentScreen={props.setCurrentScreen}
                            anchorScroll={anchorScroll}
                            toggleIndex={3}
                            toggleRoleDetails={toggleRoleDetails}
                            setToggleRoleDetails={setToggleRoleDetails}
                            title={isEnglish ? "Specialist Doctor : Cardiologist, ORL, Gynecologist..." : 'Spécialiste : Cardiologue, ORL, Gynécologue...'}
                            schedule={isEnglish ? "Full Time" : 'Temps Plein'}
                            location="Chateauroux"
                            salaryRange={isEnglish ? "7,000 to 12,000EUR" : "7,000 à 12,000EUR"}
                            timeline={isEnglish ? "Summer 2025" : 'Eté 2025'}
                            origin='careers'
                            facts={[
                                { name: isEnglish ? 'Revenue Share' : 'Part des Revenus', value: '35+%' },
                                { name: isEnglish ? 'Minimum Guaranteed Salary' : 'Salaire Minimum Garanti', value: '3,000 EUR' },
                                { name: isEnglish ? 'Work Schedule' : 'Temps de Travail', value: isEnglish ? '35 hours / week' : '35 heures / semaine' },
                                { name: isEnglish ? 'Paid Holidays' : 'Congés Payés', value: isEnglish ? '5 weeks / year' : '5 semaines / an' },
                                { name: isEnglish ? 'Free Housing' : 'Logement offert', value: isEnglish ? 'Up to 6 months' : "Jusqu'à 6 mois" },
                                { name: isEnglish ? 'Dedicated Assistant per Doctor' : 'Assistante dédiée par Médecin', value: '1' },
                                { name: isEnglish ? 'Admin Work' : 'Administratif', value: '0' },
                                { name: isEnglish ? 'Contract clauses' : 'Clauses dans le contrat', value: '0' },
                            ]}
                        />
                    </div>
                </div>
            </div>

            {/* Salary simulations */}
            <div className='px-6 sm:px-20 mt-28 sm:mt-28 sm:pb-16' id="calculator">
                <h4 className="font-bold text-gray-900 text-xl sm:text-2xl">{isEnglish ? "What you'll get paid..." : "Ce que vous serez payé..."}</h4>
                <p className="mt-2 sm:mt-4 max-w-3xl text-md leading-6 text-gray-500 sm:text-lg">{isEnglish ?
                    "Calculate your future salary, based on your family structure and taking advantage of the unique tax benefits associated with working here."
                    :
                    "Calculez votre futur salaire, sur la base de votre structure familiale et tirant parti des avantages fiscaux uniques qui vous sont offerts en travaillant ici."
                }
                </p>
                <div className='mt-6'>
                    <span className="isolate inline-flex rounded-md shadow-sm">
                        <button
                            onClick={() => {
                                setButtonClicked('dentist')
                                setLoading(true)
                                setMinShare(25)
                                setMaxShare(35)
                                setShare(30)
                                setValue(35000)
                            }}
                            className={buttonClicked === 'dentist' ?
                                "relative focus:bg-gray-900 focus:text-white inline-flex bg-gray-900 text-white items-center rounded-l-md px-5 py-2.5 text-sm sm:text-md font-semibold ring-1 ring-inset ring-gray-300"
                                :
                                "relative focus:bg-gray-900 focus:text-white inline-flex items-center rounded-l-md bg-white px-5 py-2.5 text-sm sm:text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
                            }
                        >
                            {isEnglish ? 'Dentist' : 'Dentiste'}
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setButtonClicked('general practitioner')
                                setLoading(true)
                                setMinShare(45)
                                setMaxShare(55)
                                setShare(50)
                                setValue(13800)
                            }}
                            className={buttonClicked === 'general practitioner' ?
                                "relative focus:bg-gray-900 focus:text-white inline-flex bg-gray-900 text-white items-center px-5 py-2.5 text-sm sm:text-md font-semibold ring-1 ring-inset ring-gray-300"
                                :
                                "relative focus:bg-gray-900 focus:text-white inline-flex items-center bg-white px-5 py-2.5 text-sm sm:text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
                            }>
                            {window.innerWidth > 1024 ?
                                isEnglish ? 'General Practitioner' : 'Médecin Généraliste'
                                :
                                isEnglish ? 'GP' : 'MG'
                            }
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setButtonClicked('specialist doctor')
                                setLoading(true)
                                setMinShare(30)
                                setMaxShare(40)
                                setShare(35)
                                setValue(30000)
                            }}
                            className={buttonClicked === 'specialist doctor' ?
                                "relative focus:bg-gray-900 focus:text-white rounded-r-md inline-flex bg-gray-900 text-white items-center px-5 py-2.5 text-sm sm:text-md font-semibold ring-1 ring-inset ring-gray-300"
                                :
                                "relative focus:bg-gray-900 focus:text-white rounded-r-md inline-flex items-center bg-white px-5 py-2.5 text-sm sm:text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10"
                            }>
                            {window.innerWidth > 1024 ?
                                isEnglish ? 'Specialist Doctor' : 'Spécialiste'
                                :
                                isEnglish ? 'Specialist' : 'Spécialiste'
                            }
                        </button>
                    </span>
                    <dl className="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        <RevenueTile
                            isEnglish={isEnglish}
                            handleScroller={handleScroller}
                            setLoading={setLoading}
                            name={isEnglish ? 'Share of revenues' : 'Part des revenus'}
                            stat={`${share}%`}
                            set={setShare}
                            step={1}
                            min={minShare}
                            max={maxShare}
                            value={share}
                            icon={PercentBadgeIcon}
                        />
                        <RevenueTile
                            isEnglish={isEnglish}
                            handleScroller={handleScroller}
                            setLoading={setLoading}
                            name={isEnglish ? 'Revenues generated' : "Revenus générés"}
                            stat={`${Intl.NumberFormat().format(value)} EUR`}
                            set={setValue}
                            max={100000}
                            step={1000}
                            value={value}
                            icon={CurrencyEuroIcon}
                        />
                        <RevenueTile
                            mobileRef={mobileRef}
                            isEnglish={isEnglish}
                            handleScroller={handleScroller}
                            setLoading={setLoading}
                            name={isEnglish ? 'Family structure' : 'Structure familiale'}
                            stat={`${parts} fiscal share${parts > 1 ? 's' : ''}`}
                            married={married}
                            setMarried={setMarried}
                            set={setKids}
                            max={4}
                            step={1}
                            value={kids}
                            icon={FaceSmileIcon}
                        />
                    </dl>
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        <RevenueTile
                            type="gross"
                            isEnglish={isEnglish}
                            name={isEnglish ? 'Gross salary' : "Salaire brut"}
                            stat={`${Intl.NumberFormat().format(gross)} EUR`}
                            icon={CircleStackIcon}
                            salary={true}
                        />
                        <RevenueTile
                            type="net"
                            isEnglish={isEnglish}
                            name={isEnglish ? 'Net salary' : 'Salaire net'}
                            stat={`~${Intl.NumberFormat().format(net)} EUR`}
                            icon={BanknotesIcon}
                            salary={true}
                            loading={loading}
                        />
                        <RevenueTile
                            type="netReduced"
                            isEnglish={isEnglish}
                            name={isEnglish ? 'Net salary with tax benefits' : 'Salaire net avec avantages fiscaux'}
                            stat={`~${Intl.NumberFormat().format(netReduced)} EUR`}
                            icon={CheckBadgeIcon}
                            salary={true}
                            loading={loading}
                        />
                    </dl>
                </div>
            </div>

            {/* Career requirements slider */}
            <div className='mt-20'>
                <CareerRequirements
                    isEnglish={isEnglish}
                    features={[
                        {
                            name: isEnglish ? 'French proficiency' : "Maîtrise du français",
                            description: isEnglish ?
                                "You will need to reach an ECRL B2 level of French to register in France as a doctor. It is important you can hold a conversation with the doctor's council and later on, with your future patients."
                                :
                                "Vous devrez atteindre un niveau de français ECRL B2 pour vous inscrire en tant que médecin en France. Il est important que vous puissiez tenir une conversation en français avec le conseil de l'ordre de votre région et, plus tard, avec vos futurs patients."
                            ,
                            icon: LanguageIcon,
                        },
                        {
                            name: isEnglish ? 'Experience' : 'Expérience',
                            description: isEnglish ?
                                'We are looking for dentists and specialists with a minimum of 3 years of experience. GP require a valid specialty on top of their base diploma. You need to be able to independantly and confidently perform standard treatments and take care of your patients.'
                                :
                                "Nous recherchons des dentistes et spécialistes avec un minimum de 3 ans d'expérience. Les médecins généralistes doivent avoir une spécialité valide. Vous devrez savoir effectuer de façon indépendante tous les traitements standards et prendre soin de vos patients."
                            ,
                            icon: ShieldCheckIcon,
                        },
                        {
                            name: isEnglish ? 'Other requirements' : "Autres exigences",
                            description: isEnglish ?
                                'EU citizenship and a doctor diploma from an EU university. An open mind and a strong desire to explore medicine in France. Team player, motivated and eager to learn.'
                                :
                                "Citoyenneté européenne et diplôme de médecine d'une université de l'UE. Ouverture d'esprit et une forte envie de s'expatrier en tant que médecin en France. Esprit d'équipe, motivé(e) et désireux d'apprendre."
                            ,
                            icon: DocumentMagnifyingGlassIcon,
                        },
                    ]}
                />
            </div>
            <div className="custom-shape-divider-top-1725819627">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
                </svg>
            </div>

            {/* Contact and form */}
            <div className='mt-14 sm:mt-20'>
                <ContactForm isEnglish={isEnglish} />
            </div>
        </div>
    )
}

export default Jobs