export default function CareerRequirements(props) {
    const { isEnglish, features } = props
    return (
        <div className="px-4 sm:px-20 py-20 rounded-t-xl sm:rounded-none" style={{ backgroundColor: '#231a2e' }}>
            <div className="mx-auto max-w-7xl px-4 lg:px-8">
                <p className="text-md sm:text-lg text-gray-300">{isEnglish ? "Working as a Doctor in France" : 'Travailler comme médecin en France'}</p>
                <h4 className="mt-2 sm:mt-1 text-xl font-bold tracking-tight text-white sm:text-2xl">{isEnglish ? "What's required to succeed" : "Ce qu'il vous faut pour réussir"}</h4>
                <div className="mx-auto mt-10 max-w-2xl lg:max-w-none">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-12 gap-y-10 sm:gap-x-16 sm:gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="text-xl sm:text-2xl font-semibold leading-7 text-white">
                                    <div className="mb-3 sm:mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
                                        <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-1 flex flex-auto flex-col text-sm sm:text-lg leading-7 text-gray-300">
                                    <p className="flex-auto">{feature.description}</p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
