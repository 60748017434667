import { useEffect } from 'react'

export default function Legal(props) {
    const { isEnglish, setCurrentScreen } = props

    useEffect(() => {
        setCurrentScreen(null)
    }, [setCurrentScreen])

    return (
        <div className="bg-white px-6 py-32 lg:px-8">
            <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                <p className="text-base font-semibold leading-7 text-indigo-600">{isEnglish ? 'Legal' : 'Réglementation'}</p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{isEnglish ? 'Regulatory Disclaimers' : 'Mentions Légales'}</h1>
                <p className="mt-6 text-sm sm:text-md leading-6">
                    {isEnglish ? 'novamedical.io is edited by company ADS Groupe, SASU with a share capital of 1000 €.' : 'novamedical.io est édité par la SASU ADS Groupe, SASU au capital de 1000 €.'}<br />
                    {isEnglish ? 'RCS Number :' : 'Numéro de RCS :'} 931 052 609 <br />
                    {isEnglish ? 'Head office :' : 'Siège social :'} 2 rue du Gué de la Chapelle 36250 Saint-Maur, FRANCE <br />
                    {isEnglish ? 'Publishing editor' : 'Directeur de publication'} : Hadrien MAUVE<br />
                    Contact : {isEnglish ? 'Email us at ' : 'Nous contacter via notre adresse mail'}
                    <a href={'mailto:hadrien@novamedical.io'} className='font-semibold' alt=""> hadrien@novamedical.io</a>
                    <br /><br />
                    {isEnglish ? "This website is hosted by Netlify Inc, headquartered at" : "L'hébergement du site est assuré par la société HEROKU Inc. dont le siège est situé"} :
                    512 2nd Street, Suite 200
                    San Francisco, CA 94107
                    USA

                </p>
                <p className="text-lg mt-10 font-semibold leading-7">{isEnglish ? 'Personal data' : 'Données personnelles'}</p>
                <p className="mt-2 text-sm sm:text-md leading-6">
                    {isEnglish ? "The information shared with us are only used in the context of our commercial relationship with you. You are sharing it voluntarily with us through signing up to our newsletter or filling in our application form. Your personal information will never be sold to any third parties." : "Toutes les informations que vous nous communiquez ne sont utilisées que dans le cadre de votre relation commerciale avec novamedical.io. Elles proviennent de l’enregistrement volontaire de votre adresse e-mail et des réponses à notre formulaire de contact. Ces informations ne sont jamais revendues."}
                </p>
                <p className="text-lg mt-10 font-semibold leading-7">{isEnglish ? 'Confidentiality' : 'Confidentialité'}</p>
                <p className="mt-2 text-sm sm:text-md leading-6">
                    {isEnglish ? "All information provided through your visits to our website are striclty confidential. ADS Groupe commits to respecting the confidentiality of this information and to process them in accordance with French law 'Informatique et Libertés' of January 6th, 1978" : "Toutes les informations fournies lors de vos visites sur novamedical.io sont strictement confidentielles. ADS Groupe s’engage à respecter la confidentialité de vos données personnelles et à les traiter dans le respect de la loi Informatique et Libertés du 6 janvier 1978."}
                </p>
                <p className="text-lg mt-10 font-semibold leading-7">{isEnglish ? 'Privacy' : 'Vie Privée'}</p>
                <p className="mt-2 text-sm sm:text-md leading-6">
                    {isEnglish ? "Your personal data is used by ADS Groupe in order to deliver you a service." : 'Les données personnelles sont utilisées par ADS Groupe afin de pouvoir délivrer son service.'}<br /><br />
                    {isEnglish ? "- Emails and phone numbers are used to contact applicants, organize interviews and update them on their application to one of our job posting" : "- Les emails et les numéros de téléphone afin de contacter le postulant, organiser des entretiens et l'informer sur l'évolution de sa candidature."}<br /><br />
                    {isEnglish ? "- Addresses and first name / last name of clients are used in order to share documents related to their application and, if applicable, to their registration with the local doctors' council in France" : "- Les adresses et prénoms / noms des clients afin de pouvoir envoyer des documents relatifs à sa candidature et, le cas échéant, à son inscription à l'ordre des médecins et installation en France."}<br /><br />
                    ‍
                    {isEnglish ? "According to French law 'Informatique et Libertés' of January 6th, 1978, you have the right to access your data, oppose its use and have all information pertaining to you amended and/or deleted. You can exercise those rights by contacting us with your name and address at : " : "En vertu de la loi Informatique et Libertés du 6 janvier 1978, vous disposez d’un droit d’accès, d’un droit d’opposition, d’un droit de rectification et/ou de suppression pour les renseignements vous concernant. Vous pouvez exercer ceux-ci en adressant votre demande (en indiquant vos adresse de courrier électronique, nom, prénom, adresse postale), via notre "}
                    <a href={'mailto:hadrien@novamedical.io'} className='font-semibold' alt="">{isEnglish ? 'our contact email' : 'email de contact'}</a>
                    {isEnglish ? ' or by written mail at :' : 'ou par courrier postal à l’adresse suivante : '}
                    SASU ADS Groupe, 2 rue du Gué de la Chapelle, 36250 Saint-Maur.
                </p>
            </div>
        </div>
    )
}
