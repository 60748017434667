import React, { useEffect, useState } from 'react'
import { RocketLaunchIcon } from "@heroicons/react/20/solid";

import './Home.css'
import { anchorScroll } from '../../helpers/helperFunctions';

// Import pics
import learnFrench from '../../assets/learnFrench.jpg'
import ordres from '../../assets/ordres.png'
import airport from '../../assets/airport.jpg'
import teamWelcome from '../../assets/teamWelcome.jpg'
import dentistWork from '../../assets/dentistWork.jpg'
import dentistFacts from '../../assets/dentistFacts.jpg'
import gpFacts from '../../assets/gpFacts.jpg'
import specialistFacts from '../../assets/specialistFacts.jpg'

// Import components
import ThreeBlocks from '../../components/ThreeBlocks'
import DarkPanel from '../../components/DarkPanel'
import RoleTile from '../../components/RoleTile'
import ActiveSlider from '../../components/ActiveSlider'
import MapLocales from '../../components/MapLocales';
import ContactForm from '../../components/ContactForm';

function Home(props) {
    const [scrolledLocaleMain, setScrolledLocaleMain] = useState(0)
    const [toggleRoleDetails, setToggleRoleDetails] = useState(null)
    const { isEnglish } = props

    useEffect(() => {
        if (!localStorage.getItem('home')) {
            //Effects
            const bounce = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('bounce');
                    }
                });
            });

            bounce.observe(document.querySelector('.heroTitle'));
            bounce.observe(document.querySelector('.heroSubtitle'));
            bounce.observe(document.querySelector('.heroButton'))
            localStorage.setItem('home', true)
        }
    }, [])


    return (
        <div className='overflow-x-hidden'>

            {/* Home hero with separator */}
            <div className='heroContainer'>
                <div className='heroTextContainer'>
                    <h1 className='heroTitle mb-1 sm:mb-3 md:text-6xl md:mb-1 leading-tight text-3xl w-full'>{isEnglish ? 'DOCTORS, YOUR NEW LIFE IN FRANCE AWAITS...' : 'MEDECINS, VOTRE NOUVELLE VIE VOUS ATTEND…'}</h1>
                    <h2 className='heroSubtitle text-xl sm:text-3xl max-w-3xl'>{isEnglish ? 'Join one of our private clinics and advance your medical career today!' : "Rejoignez l'une de nos cliniques privées et boostez votre carrière!"}</h2>
                    <a href="https://calendly.com/mauve-hadrien/intro-call" target="_blank" rel="noreferrer" className="">
                        <button className='heroButton flex items-center inline-block mt-3 rounded-md bg-black mt-10 px-8 py-3 text-sm sm:text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>

                            {isEnglish ? 'Get started' : "C'est parti"}
                            <RocketLaunchIcon className='w-5 h-5 ml-3' />
                        </button>
                    </a>
                </div>
            </div>
            <div className="custom-shape-divider-bottom-1725632977">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                </svg>
            </div>

            {/* Three items about being a doctor in France */}
            <div className='px-6 sm:px-20 mt-14 sm:mt-20'>
                <ThreeBlocks isEnglish={isEnglish} />
            </div>

            {/* High level pitch */}
            <div className='px-6 sm:px-20 mt-14 sm:mt-28'>
                <DarkPanel isEnglish={isEnglish} anchorScroll={anchorScroll} />
            </div>

            {/* Our job opportunities */}
            <div className='px-6 sm:px-32 mt-24 sm:mt-32'>
                <div className="relative bg-white py-8 sm:py-12 px-4 sm:px-8 rounded-xl shadow-xl text-center" id="careers">
                    <div className='heroPatternStars absolute bottom-0 left-0 -translate-x-24 translate-y-8 sm:-translate-x-12 sm:translate-y-12 -z-10' />
                    <div className='heroPatternStarsH absolute top-0 right-0 translate-x-32 -translate-y-8 sm:translate-x-12 sm:-translate-y-12 -z-10' />
                    <h3 className="text-md sm:text-lg font-semibold leading-7 text-indigo-600">{isEnglish ? "CAREERS IN FRANCE" : "CARRIERES EN FRANCE"}</h3>
                    <h2 className="mt-0 sm:mt-2 text-2xl sm:text-3xl font-bold tracking-tight text-gray-900">
                        {isEnglish ? "WE ARE HIRING" : 'ON RECRUTE'}
                    </h2>
                    <div className='mt-0 sm:mt-12'>
                        <RoleTile
                            index={'dentist'}
                            isEnglish={isEnglish}
                            setCurrentScreen={props.setCurrentScreen}
                            anchorScroll={anchorScroll}
                            toggleIndex={1}
                            toggleRoleDetails={toggleRoleDetails}
                            setToggleRoleDetails={setToggleRoleDetails}
                            title={isEnglish ? "Dentist" : 'Dentiste'}
                            schedule={isEnglish ? "Full Time" : 'Temps Plein'}
                            location="Chateauroux"
                            salaryRange={isEnglish ? "6,000 to 15,000EUR" : "6,000 à 15,000EUR"}
                            timeline={isEnglish ? "Summer 2025" : 'Eté 2025'}
                            origin='home'
                            facts={[
                                { name: isEnglish ? 'Revenue Share' : 'Part des Revenus', value: '30+%' },
                                { name: isEnglish ? 'Minimum Guaranteed Salary' : 'Salaire Minimum Garanti', value: '3,000 EUR' },
                                { name: isEnglish ? 'Work Schedule' : 'Temps de Travail', value: isEnglish ? '35 hours / week' : '35 heures / semaine' },
                                { name: isEnglish ? 'Paid Holidays' : 'Congés Payés', value: isEnglish ? '5 weeks / year' : '5 semaines / an' },
                                { name: isEnglish ? 'Free Housing' : 'Logement offert', value: isEnglish ? 'Up to 6 months' : "Jusqu'à 6 mois" },
                                { name: isEnglish ? 'Dedicated Assistant per Doctor' : 'Assistante dédiée par Médecin', value: '1' },
                                { name: isEnglish ? 'Admin Work' : 'Administratif', value: '0' },
                                { name: isEnglish ? 'Contract clauses' : 'Clauses dans le contrat', value: '0' },
                            ]}
                            factsImg={dentistFacts}
                        />
                        <RoleTile
                            index={'GP'}
                            isEnglish={isEnglish}
                            setCurrentScreen={props.setCurrentScreen}
                            anchorScroll={anchorScroll}
                            toggleIndex={2}
                            toggleRoleDetails={toggleRoleDetails}
                            setToggleRoleDetails={setToggleRoleDetails}
                            title={isEnglish ? "General Practitioner" : 'Médecin Généraliste'}
                            schedule={isEnglish ? "Full Time" : 'Temps Plein'}
                            location="Chateauroux"
                            salaryRange={isEnglish ? "6,900 to 10,000EUR" : "6,900 à 10,000EUR"}
                            timeline={isEnglish ? "Hiring" : 'Immédiatement'}
                            origin='home'
                            facts={[
                                { name: isEnglish ? 'Revenue Share' : 'Part des Revenus', value: '50%' },
                                { name: isEnglish ? 'Minimum Guaranteed Salary' : 'Salaire Minimum Garanti', value: '6,900 EUR' },
                                { name: isEnglish ? 'Work Schedule' : 'Temps de Travail', value: isEnglish ? '35 hours / week' : '35 heures / semaine' },
                                { name: isEnglish ? 'Paid Holidays' : 'Congés Payés', value: isEnglish ? '5 weeks / year' : '5 semaines / an' },
                                { name: isEnglish ? 'Free Housing' : 'Logement offert', value: isEnglish ? 'Up to 6 months' : "Jusqu'à 6 mois" },
                                { name: isEnglish ? 'Dedicated Assistant per Doctor' : 'Assistante dédiée par Médecin', value: '1' },
                                { name: isEnglish ? 'Admin Work' : 'Administratif', value: '0' },
                                { name: isEnglish ? 'Contract clauses' : 'Clauses dans le contrat', value: '0' },
                            ]}
                            factsImg={gpFacts}
                        />
                        <RoleTile
                            index={'specialist'}
                            isEnglish={isEnglish}
                            setCurrentScreen={props.setCurrentScreen}
                            anchorScroll={anchorScroll}
                            toggleIndex={3}
                            toggleRoleDetails={toggleRoleDetails}
                            setToggleRoleDetails={setToggleRoleDetails}
                            title={isEnglish ? "Specialist Doctor : Cardiologist, ORL, Gynecologist..." : 'Spécialiste : Cardiologue, ORL, Gynécologue...'}
                            schedule={isEnglish ? "Full Time" : 'Temps Plein'}
                            location="Chateauroux"
                            salaryRange={isEnglish ? "7,000 to 12,000EUR" : "7,000 à 12,000EUR"}
                            timeline={isEnglish ? "Summer 2025" : 'Eté 2025'}
                            origin='home'
                            facts={[
                                { name: isEnglish ? 'Revenue Share' : 'Part des Revenus', value: '35+%' },
                                { name: isEnglish ? 'Minimum Guaranteed Salary' : 'Salaire Minimum Garanti', value: '3,000 EUR' },
                                { name: isEnglish ? 'Work Schedule' : 'Temps de Travail', value: isEnglish ? '35 hours / week' : '35 heures / semaine' },
                                { name: isEnglish ? 'Paid Holidays' : 'Congés Payés', value: isEnglish ? '5 weeks / year' : '5 semaines / an' },
                                { name: isEnglish ? 'Free Housing' : 'Logement offert', value: isEnglish ? 'Up to 6 months' : "Jusqu'à 6 mois" },
                                { name: isEnglish ? 'Dedicated Assistant per Doctor' : 'Assistante dédiée par Médecin', value: '1' },
                                { name: isEnglish ? 'Admin Work' : 'Administratif', value: '0' },
                                { name: isEnglish ? 'Contract clauses' : 'Clauses dans le contrat', value: '0' },
                            ]}
                            factsImg={specialistFacts}
                        />
                    </div>
                </div>
            </div>

            {/* Process of expatriation */}
            <div className='px-6 sm:px-20 mt-28 sm:mt-28'>
                <div className="py-8 sm:py-20 px-2 sm:px-10 text-center bg-gray-100 rounded-2xl shadow-xl">
                    <h3 className="text-md sm:text-lg font-semibold leading-7 text-indigo-600">{isEnglish ? 'THE PROCESS' : 'LA MARCHE A SUIVRE'}</h3>
                    <h2 className="mt-0 sm:mt-2 text-2xl sm:text-3xl font-bold tracking-tight text-gray-900 mb-4 sm:mb-12">
                        {isEnglish ? '5 STEPS TO SUCCESS' : 'UNE EXPATRIATION REUSSIE EN 5 ETAPES'}
                    </h2>
                    <div className='mt-6'>
                        <ActiveSlider
                            setScrolledLocaleMain={setScrolledLocaleMain}
                            scrolledLocaleMain={scrolledLocaleMain}
                            data={[
                                {
                                    title: isEnglish ? "1 | Prepare for your new life" : '1 | Préparez-vous pour votre nouvelle vie',
                                    period: isEnglish ? '1 to 6 months' : '~1 à 6 mois',
                                    content: isEnglish ?
                                        "Let’s discuss the job requirements and our contract structure & benefits. If you don’t speak French yet, we recommend weekly lessons for at least 3 months before moving on to the next step."
                                        :
                                        "Discutons des prérequis du poste ainsi que de votre contrat et avantages. Si vous ne parlez pas encore français, nous vous recommandons des cours hebdomadaires pendant au moins 3 mois avant de passer à l'étape suivante."
                                    ,
                                    backgroundImage: learnFrench,
                                },
                                {
                                    title: isEnglish ? "2 | Register in France" : '2 | Enregistrez vous en France',
                                    period: isEnglish ? '~ 2 months' : '~ 2 mois',
                                    content: isEnglish ?
                                        "We’ll gather all the documents required to register you with the local doctors' council in a breeze. You can also use this time to keep perfecting your French in anticipation of the move."
                                        :
                                        "Nous rassemblons tous les documents requis pour vous inscrire auprès du conseil de l'ordre. C'est aussi une période pendant laquelle vous continuez à perfectionner votre français en prévision de votre arrivée."
                                    ,
                                    backgroundImage: ordres,
                                },
                                {
                                    title: isEnglish ? "3 | Discover your new home" : '3 | Découvrez votre chez-vous',
                                    period: isEnglish ? '~ 1 week' : '~ 1 semaine',
                                    content: isEnglish ?
                                        "Time to get settled. We’ll arrange lodging for you and help with admin tasks (bank account, tax registration, schools…). Start your French adventure with peace of mind."
                                        :
                                        "C'est le moment de s'installer. Nous organisons votre hébergement et vous aidons avec toutes vos formalités administratives (compte bancaire, fiscalité, école des enfants…). Démarrez votre aventure française en toute sérénité."
                                    ,
                                    backgroundImage: airport,
                                },
                                {
                                    title: isEnglish ? "4 | Meet your colleagues" : '4 | Rencontrez votre équipe',
                                    period: isEnglish ? '~ 1 week' : '~ 1 semaine',
                                    content: isEnglish ?
                                        "Welcome! Let’s give you a tour and introduce you to the team. We’ll also start briefing you on the specifics of medicine in France (social security refunds, prescriptions...)."
                                        :
                                        "Bienvenue! Nous vous faisons visiter le cabinet et vous présentons à vos nouveaux collègues. Nous vous informons aussi sur les spécificités de la médecine en France (sécurité sociale, ordonances...)."
                                    ,
                                    backgroundImage: teamWelcome,
                                },
                                {
                                    title: isEnglish ? "5 | Jumpstart your career" : '5 | Faites décoller votre carrière',
                                    period: isEnglish ? 'Game on!' : "C'est parti!",
                                    content: isEnglish ?
                                        "Let’s get started. Our commitment to you is a full agenda, the best support team you can hope for and top-notch equipments. Time to take your career to the next level!"
                                        :
                                        "Commençons. Nous vous garantissons un planning rempli, la meilleure équipe support dont vous pouviez rêver et des équipements modernes. Il est temps de faire passer votre carrière au niveau supérieur!"
                                    ,
                                    backgroundImage: dentistWork,
                                },
                            ]}
                            arrows={true}
                            slides={2}
                        />
                    </div>
                </div>
            </div>

            {/* Map container with separator */}
            <div className='mt-20'>
                <MapLocales isEnglish={isEnglish} anchorScroll={anchorScroll} />
            </div>
            <div className="custom-shape-divider-top-1725819627">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
                </svg>
            </div>

            {/* Contact and form */}
            <div className='mt-14 sm:mt-20'>
                <ContactForm isEnglish={isEnglish} />
            </div>
        </div>
    )
}

export default Home