import { CheckBadgeIcon, StarIcon, RocketLaunchIcon } from '@heroicons/react/24/outline'

export default function ThreeBlocks(props) {
    const { isEnglish } = props

    const features = [
        {
            name: isEnglish ? 'Guaranteed Work' : 'Plannings remplis',
            description:
                isEnglish ?
                    "With increasing needs and not enough doctors, France needs more practitioners to keep up. You’re guaranteed a full agenda from day one if you join us."
                    :
                    "Avec des besoins importants et une offre de santé trop faible, la France a besoin de plus de médecins. Vous avez la garantie d'un agenda rempli dès le premier jour.",
            icon: CheckBadgeIcon,
        },
        {
            name: isEnglish ? 'Quality Healthcare' : "Soins de qualité",
            description:
                isEnglish ?
                    'Our doctors work in modern clinics and handle a wide range of treatments, guiding patients towards the best care. This is the ideal place to grow and develop professionally.'
                    :
                    "Nos médecins travaillent dans des cliniques bien équipées et réalisent une large gamme de soins, guidant les patients vers le traitement le plus adapté. C'est l'endroit idéal pour vous développer professionnellement.",
            icon: StarIcon,
        },
        {
            name: isEnglish ? 'Rewarding Careers' : "Carrières enrichissantes",
            description:
                isEnglish ?
                    "You worked hard to become a doctor? Make the choice to upgrade your career in France. Our doctors earn great money on normal working hours. You could too."
                    :
                    "Vous avez travaillé dur pour devenir médecin ? Faites le choix d'accélerer votre carrière en France. Nos médecins gagnent un salaire confortable en horaires normaux. Pourquoi pas vous?",
            icon: RocketLaunchIcon,
        },
    ]
    return (
        <div className="bg-white text-center">
            <h3 className="text-md sm:text-lg font-semibold leading-7 text-indigo-600">{isEnglish ? 'PRIVATE CLINICS IN FRANCE' : 'CLINIQUES PRIVEES EN FRANCE'}</h3>
            <h2 className="mt-0 sm:mt-2 text-2xl sm:text-3xl tracking-tight text-gray-900">
                {isEnglish ? 'UNLIMITED POSSIBILITIES' : 'POSSIBILITES ILLIMITEES'}
            </h2>
            <div className="careerBlock mx-auto mt-10 sm:mt-16 max-w-6xl">
                <dl className="grid max-w-xl grid-cols-1 gap-x-14 gap-y-4 sm:gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative pl-16">
                            <dt>
                                <div className="absolute left-0 top-0 flex h-10 w-10 sm:h-12 sm:w-12 items-center justify-center rounded-lg bg-indigo-600">
                                    <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                                </div>
                                <h4 className="text-xl sm:text-2xl font-bold text-gray-900">{feature.name}</h4>
                            </dt>
                            <dd className="mt-1 text-sm sm:text-lg sm:leading-7 leading-6 text-gray-600">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    )
}
