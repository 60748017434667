import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import './ActiveSlider.css'
import gmaps from '../assets/gmaps.png'

import {
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
    BriefcaseIcon,
    BuildingOfficeIcon
} from '@heroicons/react/20/solid'

import { FreeMode, Pagination } from "swiper/modules";
import { useEffect, useRef } from "react";

function ActiveSlider(props) {
    const sliderRef = useRef()

    useEffect(() => {
        sliderRef.current?.slideTo(props.scrolledLocale)
    }, [props.scrolledLocale])

    return (
        <div className={props.simplified ? "flex items-center justify-center px-2 sm:p-2" : "flex items-center justify-center"}>
            {window.innerWidth > 1024 &&
                <button onClick={() => {
                    if (props.setScrolledLocale) {
                        props.setScrolledLocale(Math.max(props.scrolledLocale - 1, 0))
                    }
                    else {
                        props.setScrolledLocaleMain(Math.max(props.scrolledLocaleMain - 1, 0))
                        sliderRef.current?.slidePrev()
                    }
                }}><ArrowLeftCircleIcon aria-hidden="true" style={(props.scrolledLocale === 0 || props.scrolledLocaleMain === 0) ? { opacity: '0.5', cursor: 'auto' } : {}} className={props.simplified ? "mr-5 h-10 w-10 flex-shrink-0 text-white" : "mr-5 h-10 w-10 flex-shrink-0 text-gray-900"} /></button>
            }
            <Swiper
                onSwiper={it => (sliderRef.current = it)}
                breakpoints={{
                    500: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    780: {
                        slidesPerView: props.slides + 1,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: props.slides + 1,
                        spaceBetween: 30
                    },
                    1600: {
                        slidesPerView: props.slides + 2,
                        spaceBetween: 30
                    }
                }}
                freeMode={false}
                navigation={true}
                pagination={{
                    clickable: false,
                }}
                modules={[FreeMode, Pagination]}
                className="max-w-[100%] lg:max-w-[100%]"
            >
                {props.data.map((item) => (
                    <SwiperSlide key={item.title}>
                        <div key={item.title} className="flex mx-2 sm:mx-0 flex-col h-full justify-between items-end bg-white p-4 sm:p-6 rounded-xl">
                            <div>
                                <div className="aspect-h-1 overflow-hidden rounded-lg">
                                    <img alt="" src={item.backgroundImage} className="object-cover object-center" />
                                </div>
                                <div className="py-6 rounded-b-xl sm:px-4">
                                    <h4 className="text-xl sm:text-2xl text-start font-bold leading-7 text-gray-900">{item.title}</h4>
                                    {!props.simplified ?
                                        <p className="mt-2 sm:mt-5 text-sm sm:text-base text-start text-gray-600">{item.content}</p>
                                        :
                                        <div className="flex flex-col sm:flex-wrap">
                                            <div className="m-1 flex items-center text-sm sm:text-md">
                                                <BriefcaseIcon aria-hidden="true" className="mr-1.5 w-4 h-4 sm:h-5 sm:w-5 flex-shrink-0" />
                                                {item.specialty}
                                            </div>
                                            <div className="m-1 flex items-center text-sm sm:text-md">
                                                <BuildingOfficeIcon aria-hidden="true" className="mr-1.5 w-4 h-4 sm:h-5 sm:w-5 flex-shrink-0 text-black-400" />
                                                {item.rooms} treatment rooms
                                            </div>
                                            <a href={item.gmapsUrl} target="_blank" rel="noreferrer" alt='' className="m-1 flex items-center text-sm sm:text-md">
                                                <img src={gmaps} alt="" className="w-4 h-4 sm:w-6 sm:h-6 mr-1.5 flex-shrink-0" />
                                                {item.address}
                                            </a>
                                        </div>
                                    }
                                </div>
                            </div>
                            {!props.simplified &&
                                <span className="w-1/3 sm:w-1/2 justify-center text-center items-center rounded-md bg-indigo-50 px-2 py-1 text-xs sm:text-sm font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 mb-6" >
                                    {item.period}
                                </span>
                            }
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            {window.innerWidth > 1024 &&
                <button onClick={() => {
                    if (props.setScrolledLocale) {
                        props.setScrolledLocale(Math.min(props.scrolledLocale + 1, props.data.length - 1))
                    }
                    else {
                        props.setScrolledLocaleMain(Math.min(props.scrolledLocaleMain + 1, 2))
                        sliderRef.current?.slideNext()
                    }
                }}><ArrowRightCircleIcon style={(props.scrolledLocale === 2 || props.scrolledLocaleMain === 2) ? { opacity: '0.5', cursor: 'auto' } : {}} aria-hidden="true" className={props.simplified ? "ml-5 h-10 w-10 flex-shrink-0 text-white" : "ml-5 h-10 w-10 flex-shrink-0 text-gray-900"} /></button>
            }
        </div>
    );
}

export default ActiveSlider