import React, { useEffect } from 'react'

// Import components
import ContactForm from '../../components/ContactForm';
import ContactHero from '../../components/ContactHero';


function Contact(props) {
    const { isEnglish } = props

    useEffect(() => {
        if (!localStorage.getItem('contact')) {
            const flipInX = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('flipInX');
                    }
                });
            });
            flipInX.observe(document.querySelector('.locationTile1'));
            flipInX.observe(document.querySelector('.locationTile2'));
            flipInX.observe(document.querySelector('.locationTile3'));
            flipInX.observe(document.querySelector('.locationTile4'));
            localStorage.setItem('contact', true)
        }
    }, [])

    return (
        <div className='overflow-x-hidden'>
            <div className='px-6 sm:px-20 pt-32 sm:mt-12'>
                <ContactHero isEnglish={isEnglish} />
            </div>
            <div className='mt-24 sm:mt-32'>
                <ContactForm isEnglish={isEnglish} />
            </div>
        </div>
    )
}

export default Contact