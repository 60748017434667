import Navbar from './screens/Navbar/Navbar';
import { Routes, Route } from "react-router-dom";
import './App.css';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module'

// Screens
import Home from './screens/Home/Home';
import Footer from './screens/Footer/Footer';
import Careers from './screens/Careers/Careers';
import About from './screens/About/About';
import Contact from './screens/Contact/Contact';
import Legal from './components/Legal';
import NotFound from './screens/NotFound';

const tagManagerArgs = {
  gtmId: 'GTM-N9JP9JC',
}

function App() {
  const [menu, setMenu] = useState(false)
  const [currentScreen, setCurrentScreen] = useState(null)
  const [isEnglish, setIsEnglish] = useState(true)

  useEffect(() => {
    setCurrentScreen(window.location.pathname)
    setIsEnglish(localStorage.getItem("language") === 'EN')
    TagManager.initialize(tagManagerArgs)
  }, [])

  useEffect(() => {
    if (isEnglish) {
      document.title = 'Nova Medical | Better careers for doctors | Relocate to France';
    }
    else {
      document.title = 'Nova Medical | La médecine réinventée | Rejoignez-nous';
    }
  }, [isEnglish])

  return (
    <div className="App overflow-x-hidden">
      <Navbar menu={menu} setMenu={setMenu} setCurrentScreen={setCurrentScreen} currentScreen={currentScreen} isEnglish={isEnglish} setIsEnglish={setIsEnglish} />

      <Routes>
        <Route path="/" element={<Home isEnglish={isEnglish} setCurrentScreen={setCurrentScreen} />} />
        <Route path="/home" element={<Home isEnglish={isEnglish} setCurrentScreen={setCurrentScreen} />} />
        <Route path="/careers" element={<Careers isEnglish={isEnglish} setCurrentScreen={setCurrentScreen} />} />
        <Route path="/about" element={<About isEnglish={isEnglish} setCurrentScreen={setCurrentScreen} />} />
        <Route path="/contact" element={<Contact isEnglish={isEnglish} setCurrentScreen={setCurrentScreen} />} />
        <Route path="/legal" element={<Legal isEnglish={isEnglish} setCurrentScreen={setCurrentScreen} />} />
        <Route path='*' element={<NotFound isEnglish={isEnglish} />} />
      </Routes>

      <Footer isEnglish={isEnglish} />
    </div>
  );
}

export default App;
