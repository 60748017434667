import { MapPinIcon } from "@heroicons/react/24/outline";
import whatsapp from '../assets/whatsapp.svg'

export default function ContactHero(props) {
    const { isEnglish } = props

    return (
        <div>
            <div className="relative rounded-xl bg-white shadow-lg">
                {window.innerWidth > 1024 &&
                    <div className='heroPatternStars absolute bottom-0 left-0 sm:-translate-x-14 sm:translate-y-14 -z-10' />
                }
                <div className='heroPatternStarsH absolute top-0 right-0 translate-x-6 -translate-y-12 sm:translate-x-14 sm:-translate-y-14 -z-10' />

                <div className="mx-auto px-6 sm:px-8 py-8 sm:px-8 lg:mx-0 max-w-8xl">
                    <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-10 lg:grid-cols-3">
                            <div>
                                <h4 className="text-xl sm:text-2xl font-bold tracking-tight text-gray-900">{isEnglish ? 'Locations' : 'Notre présence'}</h4>
                                <p className="text-md sm:text-lg mt-2 sm:mt-4 leading-6 text-gray-600">
                                    {isEnglish ?
                                        "Our private clinics are located in the Indre departement, 2h south of Paris."
                                        :
                                        "Nos cabinets sont situés dans le département de l'Indre; à 2h au sud de Paris"
                                    }
                                </p>
                            </div>
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                                <div className="locationTile1 rounded-2xl bg-gray-50 p-6 sm:p-10">
                                    <h5 className="text-md sm:text-lg font-semibold leading-7 text-gray-900">{isEnglish ? 'Dentistry - Châteauroux' : 'Odontologie - Châteauroux'}</h5>
                                    <address className="mt-1 sm:mt-3 space-y-1 text-sm not-italic leading-5 text-gray-600">
                                        <p>27 avenue Marcel Lemoine</p>
                                        <p>36000 Châteauroux</p>
                                    </address>
                                    <a
                                        href='https://maps.app.goo.gl/2ziHbKB6mb2HhRsX7'
                                        target="_blank"
                                        rel="noreferrer"
                                        type="button"
                                        className="inline-flex mt-3 items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm sm:text-md text-gray-900 shadow-md hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        {isEnglish ? 'Go there' : "S'y rendre"}
                                        <MapPinIcon aria-hidden="true" className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" />
                                    </a>
                                </div>
                                <div className="locationTile2 rounded-2xl bg-gray-50 p-6 sm:p-10">
                                    <h5 className="text-md sm:text-lg font-semibold leading-7 text-gray-900">{isEnglish ? 'Dentistry & GP - Déols' : 'Odontologie & Médecine Générale - Déols'}</h5>
                                    <address className="mt-1 sm:mt-3 space-y-1 text-sm not-italic leading-5 text-gray-600">
                                        <p>27 rue Ferdinand Gigot</p>
                                        <p>36130 Déols</p>
                                    </address>
                                    <a
                                        href='https://maps.app.goo.gl/Y7T5ifV69z7UwGXv5'
                                        target="_blank"
                                        rel="noreferrer"
                                        type="button"
                                        className="inline-flex mt-3 items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm sm:text-md text-gray-900 shadow-md hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        {isEnglish ? 'Go there' : "S'y rendre"}
                                        <MapPinIcon aria-hidden="true" className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" />
                                    </a>
                                </div>
                                <div className="locationTile3 rounded-2xl bg-gray-50 p-6 sm:p-10">
                                    <h5 className="text-md sm:text-lg font-semibold leading-7 text-gray-900">{isEnglish ? 'Orthodontics - Saint-Maur' : 'Orthodontie - Saint-Maur'}</h5>
                                    <address className="mt-1 sm:mt-3 space-y-1 text-sm not-italic leading-5 text-gray-600">
                                        <p>1 rue du 8 mai 1945</p>
                                        <p>36250 Saint-Maur</p>
                                    </address>
                                    <a
                                        href='https://maps.app.goo.gl/rfVEydtK9kSRJrvm9'
                                        target="_blank"
                                        rel="noreferrer"
                                        type="button"
                                        className="inline-flex mt-3 items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm sm:text-md text-gray-900 shadow-md hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        {isEnglish ? 'Go there' : "S'y rendre"}
                                        <MapPinIcon aria-hidden="true" className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" />
                                    </a>
                                </div>
                                <div className="locationTile4 rounded-2xl bg-gray-50 p-6 sm:p-10">
                                    <h5 className="text-md sm:text-lg font-semibold leading-7 text-gray-900">{isEnglish ? 'HQ - Saint-Maur' : 'Siège - Saint-Maur'}</h5>
                                    <address className="mt-1 sm:mt-3 space-y-1 text-sm sm:text-md not-italic leading-5 text-gray-600">
                                        <p>2 rue du Gué de la Chapelle</p>
                                        <p>36250 Saint-Maur</p>
                                    </address>
                                    <a
                                        href='https://maps.app.goo.gl/NsfnmXjF2neHnQ157'
                                        target="_blank"
                                        rel="noreferrer"
                                        type="button"
                                        className="inline-flex mt-3 items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm sm:text-md text-gray-900 shadow-md hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        {isEnglish ? 'Go there' : "S'y rendre"}
                                        <MapPinIcon aria-hidden="true" className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-16 lg:grid-cols-3">
                            <div>
                                <h4 className="text-xl sm:text-2xl font-bold tracking-tight text-gray-900">{isEnglish ? 'Get in touch' : 'Contactez-nous'}</h4>
                                <p className="mt-2 sm:mt-4 text-md leading-6 text-gray-600">
                                    {isEnglish ?
                                        "Want to know more about opportunities in the French medical field? Write us a message and we'll be in touch!"
                                        :
                                        "Vous souhaitez en savoir plus sur les opportunités dans le médical en France? Ecrivez-nous et nous vous répondrons très vite!"
                                    }
                                </p>
                            </div>
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                                <div className="rounded-2xl bg-gray-50 p-6">
                                    <h5 className="text-md sm:text-lg font-semibold leading- text-gray-900">{isEnglish ? 'Apply' : 'Postuler'}</h5>
                                    <dl className="mt-1 sm:mt-3 space-y-1 text-md sm:text-sm leading-6 text-gray-600">
                                        <div>
                                            <dt className="sr-only">Email</dt>
                                            <dd>
                                                <a href="mailto:hadrien@novamedical.io" className="font-semibold text-sm sm:text-md text-indigo-600">
                                                    hadrien@novamedical.io
                                                </a>
                                            </dd>
                                        </div>
                                        <div className="mt-2 sm:mt-4">
                                            <dt className="sr-only">WhatsApp</dt>
                                            <a href="https://wa.me/33652547516" target="_blank" rel="noreferrer" className="flex">
                                                <img src={whatsapp} className="h-5 w-5 mr-2" alt="whatsapp" />
                                                <dd className="text-sm sm:text-md">+33 6 52 54 75 16</dd>
                                            </a>
                                        </div>
                                    </dl>
                                </div>
                                {/* <div className="rounded-2xl bg-gray-50 p-6">
                                    <h5 className="text-lg font-semibold leading-7 text-gray-900">Management</h5>
                                    <dl className="mt-1 sm:mt-3 space-y-1 text-md sm:text-sm leading-6 text-gray-600">
                                        <div>
                                            <dt className="sr-only">Email</dt>
                                            <dd>
                                                <a href="mailto:hadrien@novamedical.io" className="font-semibold text-sm sm:text-md text-indigo-600">
                                                    hadrien@novamedical.io
                                                </a>
                                            </dd>
                                        </div>
                                        <div className="mt-2 sm:mt-4">
                                            <dt className="sr-only">Whatsapp</dt>
                                            <a href="https://wa.me/33647726083" target="_blank" rel="noreferrer" className="flex">
                                                <img src={whatsapp} className="h-5 w-5 mr-2" alt="whatsapp" />
                                                <dd className="text-sm sm:text-md">+33 6 47 72 60 83</dd>
                                            </a>
                                        </div>
                                    </dl>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
